<template>
  <div class="content-mail-cdg">
    <div class="data-mail-filiale-selected">
      <div v-if="error != null" class="error-message text-center ">
        <span>{{ error }}</span>
      </div>
      <template v-if="error == null && loader == false && dataToUse != null">
        <!-- <v-row class="row">
          <v-col col xs="12" sm="12" md="12">
           Title  
            <b-form-group
              label="Titre"
              label-for="title-template-ht"
              class="input-modal-champ"
            >
              <b-form-input
                autocomplete="off"
                id="title-template-ht"
                v-model="dataToUse.client.title"
              ></b-form-input> </b-form-group
          ></v-col>
        </v-row> -->
        <v-switch
          class="check-style-custom-obligee-mail"
          v-if="dataToUse != null"
          v-model="dataToUse.send_to_client"
          value="yes"
          unchecked-value="no"
          disabled
          color="#704ad1"
        >
          <template v-slot:label>
            <span class="title-check-obligee-mail"> Client</span>
          </template>
        </v-switch>

        <v-row>
          <v-col col xs="12" sm="12" md="12">
            <!--  Mail  -->
            <label class="input-modal-champ">
              Email
            </label>
            <v-text-field
              class="input-email"
              v-model="dataToUse.client.email"
              outlined
              hide-details
              clearable
              type="email"
              color="#704ad1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row cols="2" class="mr-1">
          <v-col>
            <label class="input-modal-champ" v-if="dataToUse">
              Extra mail
            </label>
          </v-col>
          <v-col>
            <v-btn
              small
              text
              @click="handleModalAddExtraMail(dataToUse.client, 'client')"
              class="button-add-mail"
              ><font-awesome-icon icon="plus"/></v-btn
          ></v-col>
        </v-row>
        <v-row class="row mb-2 mr-1">
          <v-col col xs="12" sm="12" md="12">
            <!-- Extra mail  -->
            <v-select
              placeholder="Séléctionner"
              :items="dataToUse.client.extra_mails_list"
              v-model="dataToUse.client.extra_mails"
              no-data-text="Aucun option trouvé"
              outlined
              required
              multiple
              :persistent-placeholder="true"
              :rules="[v => !!v || 'extra email est obligatoire']"
              class="msg-error"
              validate-on-blur
              :menu-props="{ bottom: true, offsetY: true }"
              color="#704ad1"
              item-color="#704ad1"
              dense
            >
            </v-select>
          </v-col>
        </v-row>
        <v-switch
          v-if="dataToUse != null"
          switch
          class="check-style-custom-obligee-mail"
          v-model="dataToUse.send_to_vendeur"
          color="#704ad1"
        >
          <template v-slot:label>
            <span class="title-check-obligee-mail"> Sociéte</span>
          </template>
        </v-switch>
        <v-row>
          <v-col col xs="12" sm="12" md="12">
            <!--  Mail  -->
            <label class="input-modal-champ">
              Email
            </label>
            <v-text-field
              class="input-email"
              v-model="dataToUse.vendeur.email"
              outlined
              hide-details
              clearable
              type="email"
              color="#704ad1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row cols="2" class="mr-1">
          <v-col>
            <label class="input-modal-champ" v-if="dataToUse">Extra mail</label>
          </v-col>
          <v-col>
            <v-btn
              text
              small
              @click="handleModalAddExtraMail(dataToUse.vendeur, 'vendeur')"
              class="button-add-mail"
              ><font-awesome-icon icon="plus"/></v-btn
          ></v-col>
        </v-row>
        <v-row class="row mb-2 mr-1">
          <v-col col xs="12" sm="12" md="12">
            <!-- Extra mail  -->
            <v-select
              placeholder="Séléctionner"
              :items="dataToUse.vendeur.extra_mails_list"
              v-model="dataToUse.vendeur.extra_mails"
              no-data-text="Aucun option trouvé"
              outlined
              dense
              required
              multiple
              :persistent-placeholder="true"
              :rules="[v => !!v || 'extra email est obligatoire']"
              class="msg-error"
              validate-on-blur
              :menu-props="{ bottom: true, offsetY: true }"
              color="#704ad1"
              item-color="#704ad1"
            >
            </v-select>
          </v-col>
        </v-row>
      </template>
    </div>
    <div class="template-mail-filiale-selected">
      <v-row class="row">
        <!-- Body -->
        <v-col col xs="12" sm="12" md="12">
          <editor
            v-model="dataToUse.html"
            :init="computedDataTinymce.init"
            api-key="fkvny27pjoyigjbydpscwei8n0785pudavymzl4qemdm7pzg"
          ></editor>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="ModalAddExtraMail"
      max-width="600px"
      scrollable
      hide-overlay
      persistent
      :retain-focus="false"
      :eager="true"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter un Extra Mail</v-label>
          <v-btn
            class="btn-close-header"
            icon
            outlined
            @click.prevent.stop="hideModalAddExtraMail()"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="mt-3">
          <v-text-field
            class="mt-2 msg-error"
            label="Extra Mail *"
            v-model="email"
            :rules="[v => !!v || 'email obligatoire']"
            validate-on-blur
            outlined
            required
            clearable
            hide-details
            :persistent-placeholder="true"
            color="#704ad1"
          ></v-text-field>
        </v-card-text>
        <div class="messageError">
          <div v-if="errors">
            <ul v-if="Array.isArray(errors)">
              <li
                class="message-error"
                v-for="(e, index) in errors"
                :key="'error' + index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else class="message-error">{{ errors }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="body-card center-text  mt-2">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#704ad1"
            :disabled="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="createExtraMail()"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<!-- <script src="//cdn.tinymce.com/4/tinymce.min.js"></script> -->
<script>
import Editor from '@tinymce/tinymce-vue';

import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      email: null,
      errors: null,
      loading: false,
      userSelectedExtraMail: null,
      ModalAddExtraMail: false
    };
  },
  props: {
    loader: { default: false },
    dataToUse: { required: true },
    error: { default: null }
  },
  methods: {
    ...mapActions(['setLisImage', 'addExtraMail']),
    limitText(count) {
      return `et  ${count} autre extra email`;
    },
    handleModalAddExtraMail(user, type) {
      this.userSelectedExtraMail = { ...user, typeUser: type };
      this.ModalAddExtraMail = true;
    },
    hideModalAddExtraMail() {
      this.ModalAddExtraMail = false;
      this.email = null;
      this.errors = null;
      this.userSelectedExtraMail = null;
    },
    async createExtraMail() {
      this.errors = null;
      this.loading = true;
      let extraMail = new FormData();
      extraMail.append('filiale_id', this.userSelectedExtraMail.id);
      extraMail.append('email', this.email);
      const response = await this.addExtraMail(extraMail);
      if (response.success == true) {
        this.loading = false;
        this.dataToUse[
          this.userSelectedExtraMail.typeUser
        ].extra_mails_list.push(response.result.email);
        this.dataToUse[this.userSelectedExtraMail.typeUser].extra_mails.push(
          response.result.email
        );
        this.hideModalAddExtraMail();
      } else {
        this.loading = false;
        this.errors = response.result;
      }
    }
  },
  computed: {
    ...mapGetters(['getImageTemplateList']),
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url };
      });
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            );
            if (freeTiny) {
              freeTiny.style.display = 'none';
            }
          },
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',

          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: 'fr_FR',
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          /* enable title field in the Image dialog*/
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          image_list: success => success(this.computedImageList),
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData();
            param.append('image', blobInfo.blob());
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url);
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message);
              });
          }
        }
      };
    }
  },
  components: {
    Editor
  }
};
</script>

<style lang="scss" scoped>
.content-mail-cdg {
  // height: calc(100vh - 120px);
  display: flex;
  .data-mail-filiale-selected {
    width: 30%;
  }
  .template-mail-filiale-selected {
    width: 70%;
  }
}
.button-add-mail {
  font-size: 10px;
  border: none;
  float: right;
  background: #b4a1e663;
  width: 25px !important;
  max-width: 25px !important;
  padding: 0 12px !important;
  min-width: 25px !important;
  border: 1px solid #704ad1;
  color: #704ad1;
  height: 25px !important;
}
.message-error {
  color: red;
  list-style: none;
}
.title-check-obligee-mail {
  color: #704ad1;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  //   margin-bottom: 10px;
}
.label-item-custom-mail-obligee {
  font-size: 9px;
}
.input-modal-champ {
  color: #704ad1;
  float: left;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px !important;
}
.input-email {
  width: 96%;
}
</style>
<style lang="scss">
.check-style-custom-obligee-mail {
  text-align-last: center;
  margin-bottom: 5px;
  display: inline-block;
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #704ad1;
    background-color: #704ad1;
  }
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #704ad17d !important;
}
.content-mail-cdg .tox-tinymce {
  height: calc(100vh - 153px) !important;
}
.content-mail-cdg {
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: rgb(224 224 224 / 92%) !important;
  }
}
</style>
